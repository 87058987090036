import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IPatientCreate, IPatientUpdate, IPatientCommentUpdate, IPatientCommentCreate, IAttachment, IPatient } from '@/interfaces';
import { State } from '../state';
import { PatientState } from './state';
import { readOnePatient } from './getters';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitClearPatients, commitSetDocumentsForPatient, commitSetPatient, commitSetPatients, commitSetComment, commitSetComments, commitSetPatientData, commitSetCountEPathology, commitSetCountFaPathology, commitSetCountFbPathology} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { parse_error_message } from './utils'
import {
    IPrescriptionInput
  } from '@/interfaces/eagreement';
  import {
      IEFactInputModelUpdate,
    ISendEFactInput
  } from '@/interfaces/efact';

type MainContext = ActionContext<PatientState, State>;

export const actions = {
    //Patients
    async actionClearPatients(context: MainContext) {
        // initialize to empty list
        commitSetPatients(context, [])
    },
    async actionGetPatient(context: MainContext, payload: { patientId: number }) {
        const response = await api.getPatient(context.rootState.main.token, payload.patientId);
        if (response) {
            commitSetPatient(context, response.data);
        }
    },
    async actionGetPatients(context: MainContext) {
        
        try {
            commitClearPatients(context)
            let skip = 0;
            let limit = 100
            let results: IPatient[] = []

            while (true){
                const response = await api.getPatients(context.rootState.main.token, skip=skip, limit=limit);
                if (response) {
                    if (response.data.length == 0){
                        break
                    }
                    results.push(...response.data)
                    await commitSetPatients(context, results)
                    skip += limit;
                }
            }

        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePatient(context: MainContext, payload: { id: number, patient: IPatientUpdate }) {
        try {
            const response = (await Promise.all([
                api.updatePatient(context.rootState.main.token, payload.id, payload.patient),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPatient(context, response.data);
            commitAddNotification(context, { content: 'Patiënt bewerkt', color: 'success' });
        } catch (error: any) {
            if (error.response && error.response.status == 422){
                commitAddNotification(context, { color: 'error', content: error.response.data.detail[0]["msg"] });
            }
            else if (error.response){
                commitAddNotification(context, { color: 'error', content: error.response.data.detail });
            }
        }
    },
    async actionDisablePatient(context: MainContext, payload: { id: number }) {
        try {
            const response = (await Promise.all([
                api.disablePatient(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Patiënt gearchiveerd', color: 'success' });
        } catch (error: any) {
            if (error.response){
                commitAddNotification(context, { color: 'error', content: error.response.data.detail });
            }
        }
    },
    async actionEnablePatient(context: MainContext, payload: { id: number }) {
        try {
            const response = (await Promise.all([
                api.enablePatient(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Patiënt geactiveerd', color: 'success' });
        } catch (error: any) {
            if (error.response){
                commitAddNotification(context, { color: 'error', content: error.response.data.detail });
            }
        }
    },
    async actionCreatePatient(context: MainContext, payload: IPatientCreate) {
        try {
            const response = (await Promise.all([
                api.createPatient(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPatient(context, response.data);
            commitAddNotification(context, { content: 'Patiënt aangemaakt', color: 'success' });
            return response.data
        } catch (error: any) {
            if (error.response && error.response.status == 422){
                commitAddNotification(context, { color: 'error', content: error.response.data.detail[0]["msg"] });
            }
            else if (error.response){
                commitAddNotification(context, { color: 'error', content: error.response.data.detail });
            }
        }
    },
    //PatientComments
    async actionGetComments(context: MainContext, payload: { patientId?: number }) {
        try {
            const response = await api.getPatientComments(context.rootState.main.token, payload.patientId);
            if (response) {
                commitSetComments(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateComment(context: MainContext, payload: { id: number, comment: IPatientCommentUpdate }) {
        try {
            const response = (await Promise.all([
                api.updatePatientComment(context.rootState.main.token, payload.id, payload.comment),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetComment(context, response.data);
            commitAddNotification(context, { content: 'Comment bewerkt', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteComment(context: MainContext, payload: { id: number }) {
        try {
            const response = (await Promise.all([
                api.deletePatientComment(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Comment verwijderd', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateComment(context: MainContext, payload: IPatientCommentCreate) {
        try {
            const response = (await Promise.all([
                api.createPatientComment(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetComment(context, response.data);
            commitAddNotification(context, { content: 'Comment aangemaakt', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    // ehealth
    async queryMemberEHealth(context: MainContext, payload: { ssin: string|null, io: string|null, io_membership: string|null, notBefore: string, notOnOrAfter: string, hospitalized: boolean, invoicing: boolean }) {
        commitSetPatientData(context, null)
        try {
            const response = (await Promise.all([
                api.queryMemberData(context.rootState.main.token, payload.ssin, payload.io, payload.io_membership, payload.notBefore, payload.notOnOrAfter, payload.hospitalized, payload.invoicing),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPatientData(context, response.data)
            commitAddNotification(context, { content: 'Gegevens opgehaald', color: 'success' });
            console.log(response.data)
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                else if (error_message.startsWith('The INSS is incorrect')){
                    error_message = 'Rijksregisternumer is niet correct'
                }
                else if (error_message.startsWith('The INSS has an invalid format')){
                    error_message = 'Rijksregisternumer is niet correct'
                }
                else if (error_message.startsWith('Inss not found on Routing algorithm')){
                    error_message = "Rijksregisternummer niet gekend bij ziekenfonds.  eHealth services kunnen niet gebruikt worden, gelieve patiënt manueel in te voeren."
                }
                else if (error_message == 'Multiple errors: The value of contactType is hospitalized and member is not hospitalized, Niss not known in IO'){
                    error_message = 'Patiënt is niet gehospitaliseerd'
                }
                else if (error_message == 'The value of contactType is hospitalized and member is not hospitalized'){
                    error_message = 'Patiënt is niet gehospitaliseerd'
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async consultEAgreement(context: MainContext, payload: { patientId: number, useInsurancymembership: boolean}) {
        try {
            await Promise.all([
                api.consultEAgreement(context.rootState.main.token, payload.patientId, payload.useInsurancymembership),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ])
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                if (error.response.status == 400){
                    error_message = parse_error_message(error.response.data.detail)
                }
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
            return []
        }
    },
    async getAgreements(context: MainContext, payload: { patientId: number }) {
        try {
            const response = (await Promise.all([
                api.getAgreements(context.rootState.main.token, payload.patientId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            let p = readOnePatient(context)(payload.patientId);
            if (p){
                p.agreements = response.data;
                commitSetPatient(context, p)
            }
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                if (error.response.status == 400){
                    error_message = parse_error_message(error.response.data.detail)
                }
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
            return []
        }
    },
    async messagesEAgreement(context: MainContext, payload: {patientId: number}) {
        try {
            const response = (await Promise.all([
                api.asyncMessages(context.rootState.main.token, payload.patientId),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }

                let error_message_detail = error.response.data.detail.detail;
                if (error.response.status == 400){
                    error_message = parse_error_message(error.response.data.detail)
                }
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async askEAgreement(context: MainContext, payload: { 
        patient_id: number,
        product_or_service: string,
        billable_period?: string,
        serviced_date?: string,
        transaction: string,
        pre_auth_ref?: string,
        attachments: IAttachment[],
        supporting_infos?: string[],
        second_series?: boolean;
        prescription: IPrescriptionInput,
        previous_prescription?: IPrescriptionInput,
        use_insurancy_member: boolean
        
    }) {
        let request = "Aanvraag"
        if (payload.transaction == "claim-extend"){
            request = "Verlenging"
        }
        try {
            const response = (await Promise.all([
                api.askEAgreement(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];

            if (response.data.adjudication == "agreement"){
                commitAddNotification(context, { content: `${request} goedgekeurd`, color: 'success' });
            } else if (response.data.status == "complete" && payload.transaction == "claim-completeAgreement"){
                commitAddNotification(context, { content: `Aanvulling gelukt`, color: 'success' });
            } else {
                commitAddNotification(context, { content: `${request} in behandeling`, color: 'success' });
            }
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                if (error.response.status == 400){
                    error_message = parse_error_message(error.response.data.detail, request)
                }
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async cancelEAgreement(context: MainContext, payload: { 
        patient_id: number,    
        product_or_service: string,
        pre_auth_ref: string,
        use_insurancy_member: boolean
    }) {
        let request = "Annulatie"
        try {
            const response = (await Promise.all([
                api.cancelEAgreement(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];

            commitAddNotification(context, { content: `${request} gelukt`, color: 'success' });
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                if (error.response.status == 400){
                    error_message = parse_error_message(error.response.data.detail, request)
                }
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },

    // Pathology counters
    async countEPathology(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.countEPathology(context.rootState.main.token, payload.id);
            if (response) {
                commitSetCountEPathology(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async countFaPathology(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.countFaPathology(context.rootState.main.token, payload.id);
            if (response) {
                commitSetCountFaPathology(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async countFbPathology(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.countFbPathology(context.rootState.main.token, payload.id);
            if (response) {
                commitSetCountFbPathology(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    //Documents
    async addDocument(context: MainContext, payload: { patientId: number, file: File }) {
        try {
            if (!payload.patientId){
                return
            }
            const response = await api.addDocumentToPatient(context.rootState.main.token, payload.patientId, payload.file);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async deleteDocument(context: MainContext, payload: { documentId: number }) {
        try {
            const response = await api.deleteDocument(context.rootState.main.token, payload.documentId);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async downloadDocument(context: MainContext, payload: { documentId: number }) {
        try {
            const response = await api.downloadDocument(context.rootState.main.token, payload.documentId);
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async getDocumentSize(context: MainContext, payload: { documentId: string }) {
        try {
            const response = await api.getDocumentSize(context.rootState.main.token, payload.documentId);
            console.log(response)
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async getDocuments(context: MainContext, payload: { patientId: number }) {
        if (payload.patientId == null){
            return
        }
        try {
            const response = await api.getDocumentsForPatient(context.rootState.main.token, payload.patientId);
            if (response) {
                commitSetDocumentsForPatient(context, {patientId: payload.patientId, docs: response.data});
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    //eFact
    async actionSendEFact(context: MainContext, payload: ISendEFactInput) {
        try {
            const response = (await Promise.all([
                api.sendEFact(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];

            commitAddNotification(context, { content: `Aanvraag eFact gelukt`, color: 'success' });
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (!error_message){
                    error_message = error.response.data.detail
                }
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async actionUpdateFact(context: MainContext, payload: {id: number, model: IEFactInputModelUpdate}) {
        try {
            const response = (await Promise.all([
                api.updateEFact(context.rootState.main.token, payload.id, payload.model),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];

            commitAddNotification(context, { content: `Correctie eFact verzonden`, color: 'success' });
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (!error_message){
                    error_message = error.response.data.detail
                }
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async actionGetEFactMessages(context: MainContext) {
        try {
            const response = (await Promise.all([
                api.getEFactMessages(context.rootState.main.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (!error_message){
                    error_message = error.response.data.detail
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
            return []
        }
    },
    async actionGetEFacts(context: MainContext) {
        try {
            const response = (await Promise.all([
                api.getEFacts(context.rootState.main.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (!error_message){
                    error_message = error.response.data.detail
                }
                let error_message_detail = error.response.data.detail.detail;
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
            return []
        }
    },
};

const { dispatch } = getStoreAccessors<PatientState, State>('');

export const dispatchCreatePatient = dispatch(actions.actionCreatePatient);
export const dispatchClearPatients = dispatch(actions.actionClearPatients);
export const dispatchGetPatients = dispatch(actions.actionGetPatients);
export const dispatchGetPatient = dispatch(actions.actionGetPatient);
export const dispatchUpdatePatient = dispatch(actions.actionUpdatePatient);
export const dispatchDisablePatient = dispatch(actions.actionDisablePatient);
export const dispatchEnablePatient = dispatch(actions.actionEnablePatient);

export const dispatchCreatePatientComment = dispatch(actions.actionCreateComment);
export const dispatchGetPatientComments = dispatch(actions.actionGetComments);
export const dispatchUpdatePatientComment = dispatch(actions.actionUpdateComment);
export const dispatchDeletePatientComment = dispatch(actions.actionDeleteComment);

export const dispatchQueryMemberData = dispatch(actions.queryMemberEHealth);
export const dispatchConsultEAgreement = dispatch(actions.consultEAgreement);
export const dispatchGetAgreements = dispatch(actions.getAgreements);
export const dispatchMessagesEAgreement = dispatch(actions.messagesEAgreement);

export const dispatchAskEAgreement = dispatch(actions.askEAgreement);
export const dispatchCancelEAgreement = dispatch(actions.cancelEAgreement);

export const dispatchCountEPathology = dispatch(actions.countEPathology);
export const dispatchCountFaPathology = dispatch(actions.countFaPathology);
export const dispatchCountFbPathology = dispatch(actions.countFbPathology);

export const dispatchAddDocumentToPatient = dispatch(actions.addDocument);
export const dispatchGetDocumentsForPatient = dispatch(actions.getDocuments);
export const dispatchDeleteDocument = dispatch(actions.deleteDocument);
export const dispatchDownloadDocument = dispatch(actions.downloadDocument);
export const dispatchSendEFact = dispatch(actions.actionSendEFact);
export const dispatchUpdateEFact = dispatch(actions.actionUpdateFact);
export const dispatchGetEFacts = dispatch(actions.actionGetEFacts);
export const dispatchGetEFactMessages = dispatch(actions.actionGetEFactMessages);
export const dispatchGetDocumentSize = dispatch(actions.getDocumentSize);
