import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { InvoiceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { dispatchCheckApiError } from '../main/actions';
import { commitSetInvoices, commitRemoveInvoice } from './mutations';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { IInvoiceCreate } from '@/interfaces';
import { commitRemovePrescription } from '../prescriptions/mutations';

type MainContext = ActionContext<InvoiceState, State>;

export const actions = {
    async actionCreateInvoice(context: MainContext, payload: IInvoiceCreate) {
        try {
            const loadingNotification = { content: 'opslaan', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createInvoice(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Factuur aangemaakt', color: 'success' });
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionEmailInvoice(context: MainContext, id: number) {
        try {
            const loadingNotification = { content: 'email verzenden', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.emailInvoice(context.rootState.main.token, id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Factuur verzonden', color: 'success' });
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteInvoice(context: MainContext, payload: { id: number }) {
        try {
            const loadingNotification = { content: 'verwijderen', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteInvoice(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveInvoice(context, payload.id);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Factuur verwijderd', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<InvoiceState, State>('');

export const dispatchCreateInvoice = dispatch(actions.actionCreateInvoice);
export const dispatchDeleteInvocie = dispatch(actions.actionDeleteInvoice);
export const dispatchEmailInvoice = dispatch(actions.actionEmailInvoice)